import React from 'react';
import Route from './routes/Route';

function App() {
  return (
    <div className="App">
      <Route/>
    </div>
  );
}

export default App;
