import React from 'react';

function VSCode(props) {
    return (
        <iframe 
        src='https://github1s.com/dangvantho/reading-book-online'
        className='h-full w-full bg-dark'
        title='VSCode'
        frameBorder="0"
        >

        </iframe>
    );
}

export default VSCode;